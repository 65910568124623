// Cant use total_discount, it gets set by shopify
// We will be adding custom fields to the cart items
// fields:
// discounted: if the item is discounted
// calc_discounted_price: the discounted price
// calc_total_discount: the total discount to the item

export function discounts() {
  return {
    discountTotal: 0,
    bundleDiscountTotal: 0,
    processDiscountTotalV2() {
      Alpine.store('cart').data.items.forEach((cartItem) => {
        Alpine.store('products').data.forEach((product) => {
          if (cartItem.product_id == product.id) {
            if (product.calc_total_discount > 0 && !cartItem.title.toLowerCase().includes('free')) {
              cartItem.discounted = true
              cartItem.calc_discounted_price = product.price - product.calc_total_discount
              cartItem.calc_total_discount = product.calc_total_discount
              this.discountTotal += cartItem.calc_total_discount
            }
            if (Alpine.store('cart').containsBundle && cartItem.product_type.toLowerCase() ==
              'streaming') {
              this.bundleDiscountTotal += cartItem.price
              cartItem.discounted = true
              cartItem.calc_discounted_price = 0
              cartItem.calc_total_discount = cartItem.price
            }
            if(cartItem.handle == 'streaming-membership') {
              this.discountTotal += cartItem.price
              cartItem.discounted = true
              cartItem.calc_discounted_price = 0
              cartItem.calc_total_discount = cartItem.price
            }
          }
        })
      })
    },
    async applyProductDiscount() {
      this.clearItemDiscounts()
      Alpine.store('products').data.forEach((item) => {
        const discountedProduct = Alpine.store('cart').activeDiscountInfo?.entitled_product_ids?.find((discountedProductID) => discountedProductID == item.id)
        if (discountedProduct) {
          item.discounted = true
          if (Alpine.store('cart').activeDiscountInfo.discount_type == 'fixed_amount') {
            item.calc_discounted_price = item.price - Alpine.store('cart').activeDiscountInfo.discount_amount
            item.calc_total_discount = Alpine.store('cart').activeDiscountInfo.discount_amount
          } else {
            const value = Math.abs(parseInt(Alpine.store('cart').activeDiscountInfo?.value, 10))
            const discountAmount = Math.floor((item.price * value) / 100)
            item.calc_total_discount = discountAmount
            item.calc_discounted_price = item.price - discountAmount
          }
        }

        if(item.handle.includes('free')) {
          item.discounted = true
          item.calc_discounted_price = 0
          item.calc_total_discount = item.price
        }
      })
      this.processDiscountTotalV2()
      if (Alpine.store('cart').activeDiscount) {
        Alpine.store('utils').cart.updateCartAttributes('discount', Alpine.store('cart').activeDiscount)
      }
    },
    async updateCartDiscount() {
      let discountTotal = 0
      Alpine.store('cart').activeDiscountInfo?.entitled_product_ids?.forEach((id) => {
        Alpine.store('products').data.forEach((item) => {
          if (item.id == id) {
            if (remove) {
              item.discounted = false
              item.total_discount = 0
              item.discounted_price = item.price
            } else {
              item.discounted = true
              if (Alpine.store('cart').activeDiscountInfo.discount_type == 'fixed_amount') {
                item.discounted_price = item.price - Alpine.store('cart').activeDiscountInfo.discount_amount
              } else {
                const value = Math.abs(parseInt(Alpine.store('cart').activeDiscountInfo?.value, 10))
                const discountAmount = Math.floor((item.price * value) / 100)
                item.total_discount = discountAmount
                item.discounted_price = item.price - discountAmount
                Alpine.store('cart').data.items.forEach((cartItem) => {
                  if (cartItem.product_id == item.id && item.type.toLowerCase() != 'streaming') {
                    discountTotal += discountAmount * cartItem.quantity
                  }
                })
              }
            }
            if (remove) {
              // Remove all items from cart that match the discount entitled product ids
              const entitledProductIds = Alpine.store('cart').activeDiscountInfo?.entitled_product_ids || [];
              const updates = {};
              Alpine.store('cart').data.items.forEach((item) => {
                if (entitledProductIds.includes(item.product_id)) {
                  updates[item.key] = 0; // Set quantity to 0 to remove the item
                }
              });

              if (Object.keys(updates).length > 0) {
                fetch('/cart/update.js', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ updates }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    Alpine.store('utils').cart.updateCart();
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                  });
              }
            }
          }
        })
      })
      if (remove) {
        Alpine.store('cart').discountTotal = 0
      } else {
        Alpine.store('cart').discountTotal = Number(discountTotal.toFixed(0))
      }
      if (!remove) {
        if (Alpine.store('cart').currentMembershipProduct && Alpine.store('cart').currentBundleProduct) {
          Alpine.store('cart').data.total_discount = Alpine.store('cart').currentMembershipProduct.price
        }
      }
      if (remove) {
        if (Alpine.store('cart').activeDiscount) {
          Alpine.store('utils').cart.updateCartAttributes('discount', '')
        }
        Alpine.store('cart').activeDiscount = ''
        Alpine.store('cart').activeDiscountInfo = {}
      } else {
        if (Alpine.store('cart').activeDiscount) {
          Alpine.store('utils').cart.updateCartAttributes('discount', Alpine.store('cart').activeDiscount)
        }
      }
    },
    async removeCartDiscount() {
      // remove all discount changes from products
      // reset the discountTotal
      this.clearItemDiscounts()
      Alpine.store('utils').cookies.setCookie('cart', '', -1)
      if (Alpine.store('cart').activeDiscount) {
        Alpine.store('utils').cart.updateCartAttributes('discount', '')
      }
      Alpine.store('cart').activeDiscount = ''
      Alpine.store('cart').activeDiscountInfo = {}
    },
    async clearItemDiscounts() {
      Alpine.store('cart').data.items.forEach((item) => {
        item.discounted = false
        item.calc_discounted_price = null
        item.calc_total_discount = null
      })
      this.discountTotal = 0
      this.bundleDiscountTotal = 0
    },
  }
}


