// import AsyncAlpine from 'async-alpine';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import money from 'alpinejs-money'
import persist from '@alpinejs/persist'
import focus from '@alpinejs/focus'
import {toast} from '~/modules/toast'
import {challengeForm} from '@modules/challenge-form'
import { discounts } from '~/utils/cart/discounts';
import {location} from '@modules/location'
import {sidecartUpsell} from '~/sections/sidecart/upsell'


Alpine.plugin(focus)
Alpine.plugin(persist)
Alpine.plugin(money)
Alpine.plugin(collapse)

// AsyncAlpine.init(Alpine);
window.Alpine = Alpine
// Alpine.start()
// window.Alpine = Alpine
// window.Alpine.start()
// AsyncAlpine.start();
//

Alpine.store("toast", toast())
Alpine.data("challengeForm", challengeForm)
Alpine.store("discounts", discounts())
Alpine.store("location", location())
// Alpine.store("sidecartUpsell", sidecartUpsell)
